@import "../../styles/style.module.scss";

.pnfContainer {
  padding: 90px 0;
  @include for-size(tablet) {
    padding: 70px 0;
  }
}
.container {
  max-width: 1064px;
  margin: auto;
}
.pnfContent {
  background: #f9f9f9;
  padding: 30px;
}
.pnfTopContent {
  display: flex;
  align-items: center;
  padding: 30px 0;
  @include for-size(tablet) {
    flex-direction: column;
  }
  & > img {
    flex: 1 0 auto;
    text-align: center;
    @include for-size(tablet) {
      width: 200px;
    }
  }
  & > span {
    @include for-size(tablet) {
      font-size: 250px;
    }
    padding: 30px 0;
    font-size: 250px;
  }
}
.pnfDetails {
  flex: 1 0 auto;
  @include for-size(tablet) {
    text-align: center;
  }
  & > img {
    margin: 0 0 10px;
    @include for-size(tablet) {
      width: 250px;
    }
  }
  & > span {
    @include for-size(tablet) {
      font-size: 250px;
    }
    padding: 80px 0;
    font-size: 300px;
  }

  & h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
  }
  & p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: 4px;
    color: #c4c4c4;
  }
}
.goBackHome {
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: $secondaryColor;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 20px 0 0;
  display: block;
  & img {
    margin: 0 0 0 10px;
    display: inline-block;
  }
}
.usefulLinks {
  padding: 30px 30px 0;
  @include for-size(tablet) {
    padding: 30px 0 0;
  }
  & h3 {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    @include for-size(tablet) {
      width: 100%;
    }
    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      z-index: 0;
    }
    & span {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: $grey11;
      padding: 0 20px;
      background: #f9f9f9;
      text-transform: uppercase;
      position: relative;
      z-index: 2;
    }
  }
}
.usefulLinksList {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  & li {
    & a {
      padding: "10px 0";
      font-size: 16;
      font-weight: 500;
      line-height: "26px";
    }
  }
}
.gridContainer {
  padding: 30px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @include for-size(tablet) {
    flex-direction: column;
  }
  & h4 {
    font-size: 20;
    font-weight: 700;
    line-height: 26px;
    margin: 0 0 20px;
    text-transform: uppercase;
  }
}
.footerLinks {
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.ulContent {
  width: 30%;
  padding: 0 30px;
  @include for-size(tablet) {
    width: 100%;
    padding: 0 0 10px;
  }
}
